<template>
  <div>
    <heade currentrStr=""></heade>
    <a-breadcrumb class="brea common-width">
      <a-breadcrumb-item><a href="./#/index">大赛首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>{{ brea }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="sign common-width">
      <!-- <a-steps :current="current">
        <a-step title="参赛队伍信息" description="" />
        <a-step title="导师信息" description="" />
        <a-step title="创建状态" description="" />
      </a-steps> -->
      <el-steps :active="current" finish-status="success" align-center>
        <el-step title="参赛说明"> </el-step>
        <el-step title="参赛队伍信息"> </el-step>
        <el-step title="导师信息"></el-step>
        <el-step title="创建状态"></el-step>
      </el-steps>
      <div v-if="step == 0" style="    padding: 20px 0 0 10%;    text-align: left;">
        <VueMarkdown :source="content"  class="articalContent markdown-body"></VueMarkdown>
        <!-- <div class="shuo">
          <li>
            下载报名表，打印并 <span> 签字+盖章 </span> ，扫描 (或拍照)后作为证明材料上传。
          </li>
          <li>报名表内的 <span> 负责人信息 </span> 填写指导教师的信息，方便后续联络。</li>
          <li>
            报名表内的签字:院长、副院长、系主任签字都行~主要是为了证明本科生的身份。如果报名日期截止前无法签名， <span> 可以后续补签字和盖章 </span> 。
          </li>
          <li>至少1名指导教师，最多不能超过2名指导教师。</li>
          <li>
            报名之后，如果没收到自动部件确认也不需要担心，可能是被当做垃圾邮件过滤了，报名结束之后，才有正式的人工审核，如果有问题工作人员会电话
            确认。
          </li>
          <li>树莓派在报名结束，并人工审核完成之后才会发放，大家耐心等待。</li>
        </div> -->
        <!-- <a-button type="dashed" class="addBtn" style="margin-top:30px;margin-right:10px;" @click="down">
          电子报名表下载
        </a-button> -->
        <!-- <el-checkbox v-model="checked" style="margin-top:20px" v-if="checkedS">我已阅读并同意</el-checkbox><a v-if="checkedS" target="_blank" href="https://support.huawei.com/enterprise/zh/software-policy">《华为企业软件许可协议》</a> -->
        <a-button type="primary" class="addBtn" style=";display: block;margin: 30px auto 0;" @click="btnFun(1)">
          下一步
        </a-button>
      </div>
      <signCreat v-if="step == 1" @btnFun="btnFun"> </signCreat>
      <terCreat v-if="step == 2" @btnFun="btnFun" :tguo="true"> </terCreat>
      <statu v-if="step == 3" @btnFun="btnFun"></statu>
    </div>
  </div>
</template>
<script>
import heade from "@/components/header.vue";
import signCreat from "@/components/signCreat.vue";
import terCreat from "@/components/terCreat.vue";
import statu from "@/components/statu.vue";
import VueMarkdown from 'vue-markdown'
export default {
  data() {
    return {
      current: 0,
      checked:false,
      checkedS:false,
      step: 0,
      brea: "参赛说明",
      loadingX:false,
      content:   window.localStorage.getItem("anjing-content")
    };
  },
  components: {
    heade,
    signCreat,
    terCreat,
    statu,
    VueMarkdown
  },
  mounted(){
    if(window.localStorage.getItem("anjing-name").indexOf('编译系统挑战赛')!=-1){
      this.checkedS =  true;
      this.checked = false;
    }
    else {
      this.checkedS =  false;
      this.checked = true;
    }
  },
  methods: {
   down(){
    window.open(this.url+window.localStorage.getItem("anjing-fpath"),'_self')
   },

    btnFun(val) {
      debugger;
 
  
      if (val == 0) {
        this.brea = "参赛说明";
      
      } else if (val == 1) {
        // if(!this.checked){
        //   this.$message.info('请勾选，我已阅读并同意《华为企业软件许可协议》')
        //   return;
        // }
        this.brea = "参赛队伍信息";
      } else if (val == 2) {
        this.brea = "导师信息";
      } else {
        this.brea = "创建状态";
      }
      this.current = val;
      this.step = val;
    },
    look(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
@import '../assets/markdown.css';
.shuo{
  text-align: left;
  padding: 40px 0 0 12%;
}
.shuo li{
  text-indent: -19px;
  padding:10px 0
}
.shuo li span{
  font-weight: bold;
  color: #0285b6;
    font-size: 15px;
}
.brea {
  /* width: 1240px; */
  margin: 20px auto 0 !important;
  text-align: left;
}
.sign {
  /* width: 1240px; */
  margin: 30px auto 0 !important;
  padding-top: 20px;
  /* padding-right:40px; */
}
/deep/ .ant-steps-item-container {
  text-align: left;
}
/deep/ .ant-form {
  padding: 0 15px;
  margin: 50px auto 0;
}
.el-step__title {
  font-size: 13px !important;
}
/deep/ .ant-form-item {
  margin-bottom: 10px;
}
</style>