<template>
  <div>
    <a-result
      status="success"
      title="参赛队伍创建成功"
      sub-title="可前往”我的报名“查看/修改信息"
    >
      <template #extra>
        <a-button key="console" type="primary" @click="to('./index')"> 首页 </a-button>
        <a-button key="buy" @click="to('./enroll')"> 我的报名 </a-button>
      </template>
    </a-result>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    to(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>